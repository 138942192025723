import React from 'react';
import styles from './Main.module.scss';

export interface MainProps {}

const Main: React.FC<MainProps> = ({ children }) => {
  return <main className={styles.main}>{children}</main>;
};

export default Main;
