import Link from 'next/link';
import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import { isHoverHref } from '@/utils/utils';
import styles from './Nav.module.scss';

export interface NavProps {}

export const menusData = [
  { title: '首 页', href: '/' },
  { title: '新闻中心', href: '/xwzx' },
  { title: '会员动态', href: '/hydt' },
  { title: '政策法规', href: '/zcfg' },
  { title: '孵化空间', href: '/fhkj' },
  { title: '创业快报', href: '/cykb' },
  { title: '他山之石', href: '/tszs' },
  { title: '关于协会', href: '/gyxh' },
];

const Nav: React.FC<NavProps> = () => {
  const router = useRouter();

  const [selectNav, setSelectNav] = useState<string>(router.asPath);
  useEffect(() => {
    setSelectNav(router.asPath);
  }, [router.asPath]);

  return (
    <nav className={styles.nav}>
      <ul>
        {menusData.map((item, i) => {
          return (
            <li key={i}>
              <Link href={item.href}>
                <a
                  className={classnames(styles.li, {
                    [styles.hover]: isHoverHref(selectNav, item.href),
                  })}
                >
                  {item.title}
                </a>
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Nav;
