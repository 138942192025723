import React, { useMemo } from 'react';
import Breadcrumb from '@/components/Breadcrumb';
import { useRouter } from 'next/router';
import { fhqxhNewsItemDict } from '@/pages';
import Footer from './Footer';
import Header from './Header';
import Nav from './Nav';
import Layout from './Layout';
import Main from './Main';
import styles from './BasicLayout.module.scss';
import Tip from './Tip';

export interface BasicLayoutProps {
  showBreadcrumb?: boolean;
  // 控制顶部是否显示介绍图，目前只有会员动态用到
  showDescImg?: boolean;
}

const BasicLayout: React.FC<BasicLayoutProps> = ({
  children,
  showBreadcrumb = true,
  showDescImg = false,
}) => {
  const router = useRouter();
  const asPaths = (router.asPath as string).split('/').filter(item => {
    return item.length > 0 && item.length < 36;
  });

  const items = useMemo(() => {
    if (!showBreadcrumb) {
      return [];
    }
    return asPaths.map((filterItem: string, i: number) => {
      const href = asPaths
        .filter((_item: string, j: number) => {
          return i >= j;
        })
        .join('/');

      return {
        href: `/${href}`,
        children: fhqxhNewsItemDict[filterItem]?.cn_title || '',
      };
    });
  }, [showBreadcrumb, asPaths]);
  return (
    <Layout>
      <Tip />
      <Header />
      <Nav />
      {showDescImg && (
        <img src="/fhqxh/hydt-bg.png" alt="" className={styles.bg} />
      )}
      {showBreadcrumb && (
        <Breadcrumb items={[{ href: '/', children: '首页' }, ...items]} />
      )}
      <Main>{children}</Main>
      <Footer />
    </Layout>
  );
};

export default BasicLayout;
