import React, { useRef } from 'react';
import { GetServerSideProps } from 'next';
import Typography from 'antd/lib/typography';
import BasicLayout from '@/components/Layout/BasicLayout';
import Title from '@/components/Title';
import { LC } from '@/typgins';
import Link from 'next/link';
import { searchContents } from '@/service/cms';
import { getImageUrl, join } from '@/utils/utils';
import { Swiper, Autoplay, Pagination } from 'swiper';
import { Swiper as SwiperContainer, SwiperSlide } from 'swiper/react';
import SwiperClass from 'swiper/types/swiper-class';
import classnames from 'classnames';
import dayjs from 'dayjs';
import dynamic from 'next/dynamic';

import VerticalTitle from '@/components/Title/VerticalTitle';
import HorizontalTitle from '@/components/Title/HorizontalTitle';
import styles from './index.module.scss';

const NewsList = dynamic(import('@/components/Layout/NewsList'));

const { Paragraph } = Typography;

Swiper.use([Autoplay, Pagination]);

export interface NewsItem {
  id: string;
  title: string;
  releaseTime: string;
  src: string;
  href: string;
  abstract?: string;
  content?: string;
}

export interface HomeServerSideProps {
  newsProjects: { [key: string]: NewsItem[] };
}

export const fhqxhNewsItemDict: {
  [key: string]: { cn_title: string; us_title: string };
} = {
  fhxqh: { cn_title: '孵化器协会', us_title: 'Incubator Association' },
  xwdt: { cn_title: '新闻动态', us_title: 'NEWS' },
  zxtj: { cn_title: '最新推荐', us_title: 'RECOMMEND' },
  zxtw: { cn_title: '最新图文', us_title: 'IMAGE TEXT' },
  tzgg: { cn_title: '通知公告', us_title: 'BULLETIN' },
  hyzxdt: { cn_title: '行业最新动态', us_title: 'INDUSTRY NEWS' },
  zcfg: { cn_title: '政策法规', us_title: 'POLICIES AND REGULATIONS' },
  tszs: { cn_title: '他山之石', us_title: 'STONE OF OTHER MOUNTAINS' },
  lxwm: { cn_title: '联系我们', us_title: 'CONTACT US' },
  hyfc: { cn_title: '会员风采', us_title: 'MEMBER INFORMATION' },
  hydt: { cn_title: '会员动态', us_title: 'MEMBER NEWS' },
  gyxh: { cn_title: '关于协会', us_title: 'About the Association' },
  tjwz: { cn_title: '推荐文章', us_title: 'RECOMMENDED ARTICLE' },
  xhjj: { cn_title: '协会简介', us_title: 'Association Introduction' },
  xhzc: { cn_title: '协会章程', us_title: 'Association Charter' },
  xhzzjg: {
    cn_title: '协会组织架构',
    us_title: 'Association Organizational Structure',
  },
  fhkj: {
    cn_title: '孵化空间',
    us_title: 'INCUBATION SPACE',
  },
  xwzx: {
    cn_title: '新闻中心',
    us_title: 'NEWS CENTER',
  },
  cykb: {
    cn_title: '创业快报',
    us_title: 'Business letters',
  },
  cyds: {
    cn_title: '创业导师',
    us_title: 'Business tutor',
  },
};

export interface HomeProps extends HomeServerSideProps {}

function transformData(
  basePath: string,
  list?: any[],
  defaultimg?: string,
): NewsItem[] {
  if (!list) {
    return [];
  }
  return list.map((item: any) => {
    return {
      id: item._id,
      title: item.title,
      src: !item.cover && defaultimg ? defaultimg : getImageUrl(item.cover),
      releaseTime: item.release_time,
      href: join(basePath, item._id),
    };
  });
}

export const getServerSideProps: GetServerSideProps<HomeServerSideProps> = async () => {
  // 通知公告
  const getAnnouncements = searchContents({
    len: 8,
    page: 1,
    sortColumns: [],
    queryData: {
      // 孵化器协会
      site_code: 'fhqxh',
      // 通知公告
      channel_code: 'xwzx-tzgg',
      status: '已审核',
    },
  });
  const announcementRes = await getAnnouncements;

  // 新闻动态
  const getRealTimeNews = searchContents({
    len: 7,
    page: 1,
    sortColumns: [],
    queryData: {
      // 孵化器协会
      site_code: 'fhqxh',
      // 新闻动态
      channel_code: 'xwzx-xwdt',
      status: '已审核',
    },
  });
  const realTimeNewsRes = await getRealTimeNews;

  // 行业最新动态
  const getHydtNews = searchContents({
    len: 7,
    page: 1,
    sortColumns: [],
    queryData: {
      // 孵化器协会
      site_code: 'fhqxh',
      // 行业最新动态
      channel_code: 'hyzxdt',
      status: '已审核',
    },
  });
  const hydtNewsRes = await getHydtNews;
  // 政策法规
  const getPoliciesAndRegulations = searchContents({
    len: 7,
    page: 1,
    sortColumns: [],
    queryData: {
      // 孵化器协会
      site_code: 'fhqxh',
      // 政策法规
      channel_code: 'zcfg',
      status: '已审核',
    },
  });
  const policiesAndRegulationsRes = await getPoliciesAndRegulations;
  // 他山之石
  const getStonesFromOtherHills = searchContents({
    len: 6,
    page: 1,
    sortColumns: [],
    queryData: {
      // 孵化器协会
      site_code: 'fhqxh',
      // 他山之石
      channel_code: 'tszs',
      status: '已审核',
    },
  });
  const tszsRes = await getStonesFromOtherHills;
  // 孵化空间
  const getFhkjRes = searchContents({
    len: 6,
    page: 1,
    sortColumns: [],
    queryData: {
      site_code: 'fhqxh',
      channel_code: 'fhkj',
      status: '已审核',
    },
  });
  const fhkjRes = await getFhkjRes;
  // 联系我们
  const lxwmSearch = searchContents({
    len: 6,
    page: 1,
    sortColumns: [],
    queryData: {
      // 孵化器协会
      site_code: 'fhqxh',
      // 他山之石
      // 联系我们
      channel_code: 'lxwm',
      status: '已审核',
    },
  });

  const lxwmRes = await lxwmSearch;

  const newsProjects = {
    tzgg: transformData('/tzgg', announcementRes.data?.list),
    xwdt: transformData('/xwdt', realTimeNewsRes.data?.list),
    hydt: transformData(
      '/hydt/hydt',
      hydtNewsRes.data?.list,
      '/hydtdefaultimg.png',
    ),
    zcfg: transformData(
      '/zcfg',
      policiesAndRegulationsRes.data?.list,
      '/zcfgdefaultimg.png',
    ),
    tszs: transformData('/tszs', tszsRes.data?.list),
    fhkj: transformData('/fhkj', fhkjRes.data?.list),
    lxwm: transformData('/lxwm', lxwmRes.data?.list),
  };

  return {
    props: {
      newsProjects,
    },
  };
};

const Home: LC<HomeProps> = ({ newsProjects }) => {
  const swiperRef = useRef<SwiperClass>();

  return (
    <div className={styles.container}>
      <Title>{fhqxhNewsItemDict.fhxqh.cn_title}</Title>
      <div className={styles.content}>
        <div className={styles['xwdt-tzgg']}>
          <div className={styles.tpxw}>
            <img src={newsProjects.xwdt[0]?.src} alt="" />
            <div>
              <Paragraph
                ellipsis={{ rows: 2 }}
                className={styles['paragraph-title']}
              >
                {newsProjects.xwdt[0]?.title}
              </Paragraph>
              <Link href={newsProjects.xwdt[0]?.href || ''}>
                <a>查看详情</a>
              </Link>
            </div>
          </div>
          <div className={styles.xwdt}>
            <HorizontalTitle
              className={styles.title}
              iconSrc="/fhqxh/hyzxdt-icon.png"
              title={`${fhqxhNewsItemDict.xwdt.cn_title} `}
              moreItem={{ href: '/xwzx/xwdt' }}
            />
            <ul>
              {newsProjects.xwdt.slice(1).map(item => {
                return (
                  <li key={item.id}>
                    <Link href={item.href}>
                      <a className={styles.items}>
                        <span>{item.title}</span>
                        <span>
                          {dayjs(item.releaseTime).format('MM月DD日')}
                        </span>
                      </a>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className={styles.tzgg}>
            <HorizontalTitle
              iconSrc="/fhqxh/hyzxdt-icon.png"
              title={`${fhqxhNewsItemDict.tzgg.cn_title}`}
              moreItem={{ href: '/xwzx/tzgg' }}
            />
            <NewsList
              items={newsProjects.tzgg}
              className={styles.newsList}
              showDate={false}
            />
          </div>
        </div>

        <div className={styles['hydt-zcfg-cykb']}>
          <div className={styles['zxdt-zcfg']}>
            <div className={styles.zxdt}>
              <HorizontalTitle
                iconSrc="/fhqxh/hyzxdt-icon.png"
                title={`${fhqxhNewsItemDict.hydt.cn_title}`}
                moreItem={{ href: '/hydt' }}
              />

              <div className={styles.news}>
                <div>
                  <img src={newsProjects.hydt[0]?.src} alt="" />
                  <div>
                    <div>{newsProjects.hydt[0]?.title}</div>
                    <Link href={newsProjects.hydt[0]?.href || ''}>
                      <a className={styles.detail}>查看详情{`>`}</a>
                    </Link>
                  </div>
                </div>

                <NewsList
                  items={newsProjects.hydt.slice(1)}
                  className={styles.newsList}
                />
              </div>
            </div>

            <div className={styles.zxdt}>
              <HorizontalTitle
                iconSrc="/fhqxh/zcfg-icon.png"
                title={`${fhqxhNewsItemDict.zcfg.cn_title}`}
                moreItem={{ href: '/zcfg' }}
              />

              <div className={styles.news}>
                <div>
                  <img src={newsProjects.zcfg[0]?.src} alt="" />
                  <div>
                    <div>{newsProjects.zcfg[0]?.title}</div>
                    <Link href={newsProjects.zcfg[0]?.href || ''}>
                      <a className={styles.detail}>查看详情{`>`}</a>
                    </Link>
                  </div>
                </div>

                <NewsList
                  items={newsProjects.zcfg.slice(1)}
                  className={styles.newsList}
                />
              </div>
            </div>
          </div>

          <div className={styles['cyds-cykb']}>
            <Link href="/cyds">
              <a>
                <img src="/fhqxh/cyds.png" alt="" className={styles.cyds} />
              </a>
            </Link>
            <Link href="/cykb">
              <a>
                <img src="/fhqxh/cykb.png" alt="" className={styles.cyds} />
              </a>
            </Link>
          </div>
        </div>
      </div>

      <div className={styles.bar}>
        <img src="/fhqxh/bar.png" alt="" />
      </div>

      <div className={styles.fhkj}>
        <VerticalTitle
          className={styles.title}
          title={fhqxhNewsItemDict.fhkj.cn_title}
          moreItem={{ href: '/fhkj' }}
        />
        <div className={styles.carousel}>
          <div
            onClick={() => {
              swiperRef.current?.slidePrev();
            }}
            className={classnames(styles.arrow, styles['left-arrow'])}
          >
            <img src="/left-arrow.png" alt="右箭头" />
          </div>
          <div className={styles['avatar-list']}>
            <SwiperContainer
              slidesPerView={3}
              spaceBetween={20}
              autoplay
              onSwiper={swiper => {
                swiperRef.current = swiper;
              }}
            >
              {newsProjects.fhkj.map((item, i) => {
                return (
                  <SwiperSlide key={i}>
                    <div className={styles.swiperSlideItem}>
                      <Link href={item.href}>
                        <a>
                          <img
                            className={styles.avatar}
                            src={item.src}
                            alt="头像"
                          />
                        </a>
                      </Link>
                    </div>
                  </SwiperSlide>
                );
              })}
            </SwiperContainer>
          </div>

          <div
            onClick={() => {
              swiperRef.current?.slideNext();
            }}
            className={classnames(styles.arrow, styles['right-arrow'])}
          >
            <img src="/left-arrow.png" alt="右箭头" />
          </div>
        </div>
      </div>

      <div className={styles.tszs}>
        <HorizontalTitle
          iconSrc="/fhqxh/hyzxdt-icon.png"
          title={`${fhqxhNewsItemDict.tszs.cn_title}`}
          moreItem={{ href: '/tszs' }}
        />
        <div className={styles.tszsNews}>
          <NewsList
            items={newsProjects.tszs.slice(0, 3)}
            className={styles.newsList}
          />
          <NewsList
            items={newsProjects.tszs.slice(3, 6)}
            className={styles.newsList}
          />
        </div>
      </div>

      <div className={styles.lxwm}>
        <VerticalTitle
          className={styles.title}
          titleClassName={styles.titleClassName}
          subTitleClassName={styles.subTitleClassName}
          title={fhqxhNewsItemDict.lxwm.cn_title}
        />
        <ul className={styles.address}>
          {newsProjects.lxwm.map((item, i) => {
            return (
              <li key={i}>
                <div>
                  <img src={item.src} alt="" />
                </div>
                <span>{item.title}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

Home.getLayout = page => {
  return <BasicLayout>{page}</BasicLayout>;
};

export default Home;
