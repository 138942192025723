import React from 'react';
// import Typography from 'antd/lib/typography';
import Breadcrumb from 'antd/lib/breadcrumb';
import { BreadcrumbItemProps } from 'antd/lib/breadcrumb/BreadcrumbItem';
import styles from './index.module.scss';

// const { Paragraph } = Typography;

interface Item extends BreadcrumbItemProps {
  children: React.ReactNode;
}

export interface HomeProps {
  prefix?: string;
  items: Item[];
  separator?: React.ReactNode;
}

const Home: React.FC<HomeProps> = ({ prefix, separator, items }) => {
  return (
    <div className={styles.breadcrumb_container}>
      <div className={styles.breadcrumb}>
        <img src="/nav.png" alt="" />
        <span>{prefix}</span>
        <Breadcrumb separator={separator}>
          {items.map((item, i) => {
            return (
              <Breadcrumb.Item href={item.href} key={i}>
                {item.children}
              </Breadcrumb.Item>
            );
          })}
        </Breadcrumb>
      </div>
    </div>
  );
};

Home.defaultProps = {
  prefix: '当前位置:',
  separator: '>',
};

export default Home;
