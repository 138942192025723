import Link from 'next/link';
import React, { useState } from 'react';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import { useRouter } from 'next/router';
import styles from './Header.module.scss';

export interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const router = useRouter();
  const [search, setSearch] = useState(router?.query.query || '');
  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <Link href="/">
          <a>
            <img src="/fhqxh/logo.png" alt="logo" />
          </a>
        </Link>
        <div className={styles.info}>
          <Input
            className={styles.search}
            onChange={e => {
              setSearch(e.target.value);
            }}
            onPressEnter={() => {
              router.push({
                pathname: '/search',
                query: {
                  query: search,
                },
              });
            }}
            prefix={
              <div className={styles.searchIcon}>
                <img
                  src="/search.png"
                  style={{ width: 22, height: 22 }}
                  alt=""
                />
              </div>
            }
            suffix={
              <Link href={`/search?query=${search}`}>
                <Button type="primary" className={styles.searchButton}>
                  搜索
                </Button>
              </Link>
            }
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
