import Link from 'next/link';
import React from 'react';
import classnames from 'classnames';
import styles from './HorizontalTitle.module.scss';

export interface MoreItem {
  text?: string;
  href: string;
}

export interface HorizontalTitleProps {
  className?: string;
  titleClassName?: string;
  subTitleClassName?: string;
  iconClassName?: string;
  style?: React.CSSProperties;
  title: string;
  subTitle?: string;
  iconSrc?: string;
  moreItem?: MoreItem;
}

const HorizontalTitle: React.FC<HorizontalTitleProps> = ({
  title,
  className,
  titleClassName,
  iconClassName,
  style,
  iconSrc,
  moreItem,
}) => {
  return (
    <div className={classnames(styles.container, className)} style={style}>
      <div className={styles.titleContainer}>
        {iconSrc && (
          <img
            src={iconSrc}
            alt=""
            className={classnames(styles.icon, iconClassName)}
          />
        )}
        <div className={classnames(styles.title, titleClassName)}>{title}</div>
        {/* <div className={classnames(styles.subTitle, subTitleClassName)}>
          {subTitle}
        </div> */}
      </div>
      {moreItem && (
        <div className={styles.more}>
          <Link href={moreItem.href}>
            <a>{moreItem.text || '更多>'}</a>
          </Link>
        </div>
      )}
    </div>
  );
};

export default HorizontalTitle;
