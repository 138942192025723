import Link from 'next/link';
import React from 'react';
import styles from './Footer.module.scss';

export interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.bah}>
        江苏省高新技术创业服务中心（江苏省科技成果转化服务中心）
        版权所有&nbsp;&nbsp;
        <Link href="https://beian.miit.gov.cn/">
          <a target="_blank">
            <p>苏ICP备05046966号-14</p>
          </a>
        </Link>
      </div>
      <div>工业和信息化部ICP/IP地址/域名信息备案管理系统</div>
    </footer>
  );
};

export default Footer;
