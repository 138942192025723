import Link from 'next/link';
import { Modal, notification } from 'antd';
import React, { useContext, useMemo, useState } from 'react';
import { clearToken } from '@/utils/authority';
import { logout } from '@/service/user';
import Cookies from 'js-cookie';
import classNames from 'classnames';
import { LogoutOutlined, QqOutlined } from '@ant-design/icons';
import { getRedirectUrl } from '@/utils/utils';
import styles from './Tip.module.scss';
import { LayoutContext } from './context';
import { portal_url } from '#/projectConfig';

export interface TipProps {}

export interface ListItem {
  id: string;
  icon?: React.ReactNode;
  src?: string;
  srcHover?: string;
  onClick?: () => void;
  href?: string;
  value: string;
}
export interface AppItem {
  platform: string;
  attachment: string;
}

const myLogout = async () => {
  const logOutRes = await logout();

  if (!logOutRes.isSuccess) {
    Modal.error({
      title: '退出失败',
      content: logOutRes.msg,
    });
  }

  clearToken();
  Cookies.remove('userInfo');
  notification.success({ message: '退出成功' });

  setTimeout(() => {
    window.location.href = `/`;
  }, 1000);
};

const Tip: React.FC<TipProps> = () => {
  const [itemId, setItemId] = useState('');

  const { userInfo } = useContext(LayoutContext);

  const list: ListItem[] = useMemo(() => {
    const item: ListItem[] = [];

    if (!userInfo) {
      item.push(
        ...[
          {
            id: '1',
            src: '/tip/wydl.svg',
            srcHover: '/tip/wydl-hover.svg',
            value: '我要登录',
          },
          {
            id: '2',
            src: '/tip/wyzc.svg',
            srcHover: '/tip/wyzc-hover.svg',
            href: '/register',
            value: '我要注册',
          },
        ],
      );
    } else {
      item.push(
        ...[
          {
            id: '6',
            icon: <LogoutOutlined className={styles.icon} />,
            onClick: myLogout,
            value: '退出',
          },
        ],
      );
    }

    item.push(
      ...[
        {
          id: '3',
          icon: (
            <QqOutlined className={styles.icon} style={{ fontSize: '22px' }} />
          ),
          value: '二维码',
        },
      ],
    );

    return item;
  }, [userInfo]);

  const C = useMemo(() => {
    return list.map(item => {
      if (item.value === '我要登录') {
        return (
          <div
            className={styles.btn}
            key={item.id}
            onMouseEnter={() => {
              setItemId(item.id);
            }}
            onMouseLeave={() => {
              setItemId('');
            }}
          >
            <span className={styles.imgContainer}>
              <img src={itemId === item.id ? item.srcHover : item.src} alt="" />
              {/* <span className={styles.ti}>{item.value}</span> */}
              <div className={classNames(styles.popleft)}>
                <div className={classNames(styles.popContent)}>
                  <span className={styles.line} />
                  {[
                    {
                      title: '普通用户登录',
                      id: '1',
                      src: '/ptdl.svg',
                      onclick: () => {
                        window.location.replace(getRedirectUrl());
                      },
                    },
                    {
                      title: '管理用户登录',
                      id: '2',
                      src: '/gldl.svg',
                      onclick: () => {
                        window.location.replace(`${portal_url}/admin-login`);
                      },
                    },
                  ].map(
                    (subItem: {
                      href?: string;
                      title: string;
                      id: string;
                      src: string;
                      onclick?: () => void;
                    }) => {
                      return (
                        <a
                          onClick={subItem.onclick}
                          className={styles.a}
                          key={subItem.id}
                        >
                          <img
                            className={styles.dlimg}
                            src={subItem.src}
                            alt=""
                          />
                          {subItem.title}
                        </a>
                      );
                    },
                  )}
                </div>
                <img className={styles.arrow} src="/arrow.png" alt="" />
              </div>
            </span>
          </div>
        );
      } else if (item.value === '我要注册') {
        return (
          <div
            className={styles.btn}
            key={item.id}
            onMouseEnter={() => {
              setItemId(item.id);
            }}
            onMouseLeave={() => {
              setItemId('');
            }}
          >
            <span className={styles.imgContainer}>
              <img src={itemId === item.id ? item.srcHover : item.src} alt="" />
              <div className={classNames(styles.popleft)}>
                <div
                  className={classNames(styles.popContent)}
                  style={{ height: '55px', marginTop: '24px' }}
                >
                  <span className={styles.line} />
                  {[
                    {
                      onclick: () => {
                        window.location.replace(`${portal_url}/register`);
                      },
                      title: '个人账户注册',
                      id: '1',
                      src: '/ptdl.svg',
                    },
                    /* {
                      href: '/register?tab=2',
                      title: '法人账户注册',
                      id: '2',
                      src: '/gldl.svg',
                    }, */
                  ].map(
                    (subItem: {
                      title: string;
                      id: string;
                      src: string;
                      onclick?: () => void;
                    }) => {
                      return (
                        <a
                          onClick={subItem.onclick}
                          className={styles.a}
                          key={subItem.id}
                        >
                          <img
                            className={styles.dlimg}
                            src={subItem.src}
                            alt=""
                          />
                          {subItem.title}
                        </a>
                      );
                    },
                  )}
                </div>
                <img className={styles.arrow} src="/arrow.png" alt="" />
              </div>
            </span>
          </div>
        );
      } else if (item.value === '二维码') {
        return (
          <div
            className={styles.btn}
            key={item.id}
            onClick={item.onClick}
            onMouseEnter={() => {
              setItemId(item.id);
            }}
            onMouseLeave={() => {
              setItemId('');
            }}
          >
            <div className={styles.imgContainer}>
              {item.icon}
              <span className={styles.gzhleft}>
                <span className={styles.border}>
                  <img
                    className={styles.gzhContent}
                    src="/personal/ewm.png"
                    alt=""
                  />
                  <img className={styles.arrow} src="/arrow.png" alt="" />
                </span>
              </span>
            </div>
          </div>
        );
      } else {
        return (
          <div
            className={styles.btn}
            key={item.id}
            onClick={item.onClick}
            onMouseEnter={() => {
              setItemId(item.id);
            }}
            onMouseLeave={() => {
              setItemId('');
            }}
          >
            {item.href ? (
              <Link href={item.href || ''}>
                <a className={styles.imgContainer}>
                  {item.icon ? (
                    item.icon
                  ) : (
                    <img
                      src={itemId === item.id ? item.srcHover : item.src}
                      alt=""
                    />
                  )}
                  <span className={styles.pop}>
                    <span className={styles.ti}>{item.value}</span>
                  </span>
                </a>
              </Link>
            ) : (
              <div className={styles.imgContainer}>
                {item.icon ? (
                  item.icon
                ) : (
                  <img
                    src={itemId === item.id ? item.srcHover : item.src}
                    alt=""
                  />
                )}
                <span className={styles.pop}>
                  <span className={styles.ti}>{item.value}</span>
                </span>
              </div>
            )}
          </div>
        );
      }
    });
  }, [list, itemId]);

  return <div className={styles.tip}>{C}</div>;
};

export default Tip;
